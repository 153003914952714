import { Fragment } from "react";
import BoxTitle from "../../../TEMPLATE/components/box/title/BoxTitle";
import { applicantsCitizen } from "../../hardCodeData/DATA";
import { useHistory } from "react-router-dom";

const Advertisement = ({ title, external, internal, img }) => {
  const history = useHistory();
  return (
    <Fragment>
      <BoxTitle
        title={title}
        infoContext={""}
        externalLink={external}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      <div style={{ marginTop: 30 }} />
      <div
        style={{
          height: "163px",
          aspectRatio: "1 / 1",
          objectFit: "cover",
          cursor: "pointer",
          marginInline: "auto",
        }}
      >
        <img
          src={img}
          alt={`${title} - image`}
          style={{ height: "100%", width: "100%" }}
          onClick={() => {
            if (external) window.open(`${external}`, "_blank");
            else if (internal) history.push(`/${internal}`);
          }}
        />
      </div>
    </Fragment>
  );
};
export default Advertisement;
