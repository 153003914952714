import {
  baseUrl,
  gallerySmall,
  galleryBig,
} from "../../SMARTCITY/hardCodeData/DATA";

export const feedEnvironment_Meteo = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );
  if (req === undefined || !req.ok) return [];
  const response = await req.json();

  let data = {};
  for (let i = 0; i < response.length; i++) {
    const metrics = [];
    response[i].details.map((item) => {
      let dec = 0;
      let symbol = "";
      if (item.sensorvar_dec) dec = parseInt(item.sensorvar_dec);
      if (item.sensorvar_unit) symbol = " " + item.sensorvar_unit;

      let value = parseFloat(item.measurement).toFixed(dec) + symbol;
      if (isNaN(item.measurement)) {
        value = item.measurement;
      }

      metrics.push({
        parentId: response[i].mysensor_id,
        content: item.sensorvar_description,
        group: item.group,
        value: value,
        valid: "valid" in item ? item.valid : 1,
        icon_small: item.sensorvar_icon && gallerySmall + item.sensorvar_icon,
        icon_large: item.sensorvar_icon && galleryBig + item.sensorvar_icon,
      });
    });
    data = {
      ...data,
      [response[i].mysensor_id]: {
        metrics: metrics,
        tooltip: response[i].tooltip,
      },
    };
  }

  console.log(data);
  return data;
};

export const feedMeshlium = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );
  if (req === undefined || !req.ok) return [];
  const response = await req.json();

  let data = {};
  for (let i = 0; i < response.length; i++) {
    let metrics = [];

    response[i].details.map((item) => {
      metrics.push({
        parentId: response[i].mysensor_id,
        content: item.sensorvar_description,
        value: item.wfTotal ? item.wfTotal : item.btTotal,
        icon_small: item.sensorvar_icon && gallerySmall + item.sensorvar_icon,
        icon_large: item.sensorvar_icon && galleryBig + item.sensorvar_icon,
      });
    });
    data = {
      ...data,
      [response[i].mysensor_id]: {
        metrics: metrics,
        tooltip: response[i].tooltip ? response[i].tooltip : "",
      },
    };
  }
  return data;
};

export const feedPOIS = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );
  if (req === undefined || !req.ok) return [];
  const response = await req.json();

  let pois = [];
  response.map((item) => {
    pois.push({
      typeId: "7",
      id: item.id,
      categoryId: item.category_id,
      categoryName: item.category_name,
      title: item.name,
      position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
      address: item.address,
      smallMarker: baseUrl + item.category_small_icon,
      bigMarker: baseUrl + item.category_big_icon,
      thumbnail: item.main_image,
      images: item.images,
      content: item.portal_main_info,
      metrics: [{ content: item.category_name }],
      isActive: false,
    });
  });
  return pois;
};

export const feedFleeto = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );

  if (req === undefined || !req.ok) return [];
  const response = await req.json();

  let fleeto = [];

  response.map((item) => {
    let tempMetrics = [{ content: item.category_name }];
    if (item.speed)
      tempMetrics.push({ content: "Ταχύτητα", value: item.speed + " km/h" });
    if (item.altitude)
      tempMetrics.push({
        content: "Υψόμετρο",
        value: item.altitude + " μέτρα",
      });
    if (item.angle)
      tempMetrics.push({ content: "Γωνία", value: item.angle + " μοίρες" });
    fleeto.push({
      typeId: "8",
      id: item.id,
      categoryId: item.category_id,
      categoryName: item.category_name,
      address: item.category_name,
      title: item.name,
      position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
      smallMarker: baseUrl + item.category_small_icon,
      bigMarker: baseUrl + item.category_big_icon,
      metrics: tempMetrics,
      isActive: false,
    });
  });
  return fleeto;
};

export const feedBins = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );

  if (req === undefined || !req.ok) return [];
  const response = await req.json();

  console.log(response);
  let bins = [];

  response.map((item) => {
    let tempMetrics = [];
    tempMetrics.push(
      {
        content: "Κατάσταση",
        value: item.status === 1 ? "Απεν/μένο" : "Ενεργό",
      },
      { content: "Κωδικός αισθητήρα", value: item.mote },
      {
        content: "Τελευταία ενημέρωση",
        value: item.date?.split(":")[0] + ":" + item.date?.split(":")[1],
      },
      { content: "Συντεταγμένες κάδου", value: item.lat + " " + item.lng },
      { content: "Ποσοστό πληρότητας κάδου", value: item.value + "%" },
      {
        content: "Τάση μπαταρίας",
        value: item.voltage,
      },
      {
        content: "Θερμοκρασία",
        value: (
          <span>
            {item.temp} <sup>o</sup>C
          </span>
        ),
      }
    );

    bins.push({
      typeId: "9",
      id: item.id,
      categoryId: item.category_id,
      categoryName: item.category_name,

      title: item.location,
      position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
      smallMarker: baseUrl + item.category_small_icon,
      bigMarker: baseUrl + item.category_big_icon,
      metrics: tempMetrics,
      isActive: false,
    });
  });
  console.log(bins);
  return bins;
};

export const feedHydro = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );

  if (req === undefined || !req.ok) return [];
  const response = await req.json();

  let hydro = [];

  response.map((item) => {
    let tempMetrics = [];
    tempMetrics.push(
      { content: "Κωδικός αισθητήρα", value: item.mote },
      { content: "Ένδειξη", value: item.value + "κ.μ." },
      {
        content: "Κατάσταση",
        value: item.status === 1 ? "Ενεργό" : "Απεν/μένο",
      }
    );

    hydro.push({
      typeId: "10",
      id: item.id,
      categoryId: item.category_id,
      categoryName: item.category_name,
      address: item.date
        ? "Τελευταία ενημέρωση:" +
          item.date.split(":")[0] +
          ":" +
          item.date.split(":")[1]
        : "",
      title: item.location,
      position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
      smallMarker: baseUrl + item.category_small_icon,
      bigMarker: baseUrl + item.category_big_icon,
      metrics: tempMetrics,
      isActive: false,
    });
  });
  return hydro;
};

export const feedWifi = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );

  if (req === undefined || !req.ok) return [];

  const response = await req.json();
  console.log(response);

  const metrics = [];
  response.map((item) => {
    metrics.push({
      parentId: "101",
      content: item.title,
      value: item.total,
      valid: "valid" in item ? item.valid : 1,
      icon_large: "-",
    });
  });

  let wifi = {
    ["101"]: {
      metrics: metrics,
      tooltip: "",
    },
  };
  console.log(wifi);

  return wifi;
};

export const feedParking = async (url, signal) => {
  const req = await fetch(url, { signal }).catch((error) =>
    console.warn(error)
  );

  if (req === undefined || !req.ok) return [];
  const response = await req.json();

  let parking = [];

  response.map((item) => {
    let tempMetrics = [];
    const time = item.IsOccupied ? item.OccupiedFrom : "free";

    tempMetrics.push(
      {
        content: "Κατειλημμένη",
        value: item.IsOccupied ? "Ναι" : "Οχι",
      },
      {
        content: "Κατειλημμένη από",
        value: time,
      },
      {
        content: "Τύπος",
        value: item["Type"],
      }
    );

    parking.push({
      typeId: "24",
      id: item.id,
      categoryId: "24",
      // categoryName: item.category_name,

      title: "Θέση " + item.LabelOnStreet,
      position: { lat: parseFloat(item.Lat), lng: parseFloat(item.Lon) },
      // smallMarker: baseUrl + item.category_small_icon,
      bigMarker: baseUrl + item.icon,
      metrics: tempMetrics,
      isActive: false,
    });
  });
  return parking;
};
