import classes from "./Parking.module.css";
import BoxTitle from "../../../TEMPLATE/components/box/title/BoxTitle";
import { Fragment, useEffect, useState } from "react";
import BoxEntity from "../../../TEMPLATE/components/box/entity/BoxEntity";
import { baseUrl } from "../../hardCodeData/DATA";
import { Doughnut } from "react-chartjs-2";
import BoxSubTitle from "../../../TEMPLATE/components/box/subTitle/BoxSubTitle";
import img from "./../../assets/parking_icon.png";

import DonutChart from "../../../TEMPLATE/components/charts/DocutChart";

const initData = {
  labels: ["Ελεύθερες", "Κατειλημμένες"],
  text: "30",
  datasets: [
    {
      label: "My First Dataset",
      data: [50, 300],
      backgroundColor: ["#4FE676", "#FC1F19"],
      hoverOffset: 4,
    },
  ],
};

const parking = baseUrl + "/api/api.php?func=parkingdetails";
const Parking = ({ id, typeId, place }) => {
  const [data, setData] = useState(null);
  const [chartSourceData, setChartSourceData] = useState([]);
  const controller = new AbortController();
  const { signal } = controller;

  const getData = async () => {
    const RESPONSE = await fetch(parking, { signal });
    if (!RESPONSE || !RESPONSE.ok) return;
    const DATA = await RESPONSE.json();

    const sourceData = [
      {
        item: initData.labels[0],
        count: DATA?.[place].Places - DATA?.[place].PlacesOccupied,
      },
      { item: initData.labels[1], count: DATA?.[place].PlacesOccupied },
    ];

    setChartSourceData(sourceData);

    setData(DATA);
  };

  useEffect(() => {
    getData().catch((error) => console.warn(error));

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Fragment>
      <BoxTitle
        // icon={"im im-icon-Business-ManWoman"}
        title={"ΘΕΣΕΙΣ ΣΤΑΘΜΕΥΣΗΣ"}
        infoContext={"Ελεύθερες θέσεις για το PARKING"}
        img={img}
        link={`/map?typeId=${typeId}`}
        element={
          <i
            id="draggable"
            className="im im-icon-Double-Tap"
            style={{ fontSize: "32px", minWidth: "32px", cursor: "pointer" }}
          />
        }
      />
      <h1
        style={{
          textAlign: "center",
          marginBottom: 10,
          fontSize: 16,
          lineHeight: 1,
          fontWeight: 400,
          color: "white",
        }}
      >
        {data?.[place].ParkingName}
      </h1>
      <DonutChart data={chartSourceData} />

      {/*{data &&*/}
      {/*  data.map((item, index) => (*/}
      {/*    <BoxEntity*/}
      {/*      key={index}*/}
      {/*      icon={null}*/}
      {/*      content={`${item.ParkingName}`}*/}
      {/*      value={`${item.Places - item.PlacesOccupied} Ε.Θ.`}*/}
      {/*    />*/}
      {/*  ))}*/}
    </Fragment>
  );
};

export default Parking;
