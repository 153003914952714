import classes from "./WelcomeMessage.module.css";
import apple from "./../../../assets/images/apple.png";
import android from "./../../../assets/images/android.png";
import information from "./../../../assets/images/information_project.png";
import { useTranslation } from "react-i18next";
import {
  androidAppLink,
  informationLink,
  informationLinkEN,
  iosAppLink,
  welcomeMessageContent,
} from "../../../../SMARTCITY/hardCodeData/DATA";
import { useContext } from "react";
import LanguageContext from "../../../hooks/language-context";
import sponsor from "./../../../../SMARTCITY/assets/images/sponsor.png";

const WelcomeMessage = () => {
  const { t } = useTranslation();
  const languageCtx = useContext(LanguageContext);
  return (
    <div className={classes.welcome_placeholder}>
      <p>{t(welcomeMessageContent[0])}</p>
      <p>{t(welcomeMessageContent[1])}</p>
<img src={sponsor} alt="sponsor logo" className={classes.logo_normal} />
      <div>
	   
        <div
          className={classes.projectInfo}
          onClick={() =>
            window.open(
              languageCtx.languageCode === "en"
                ? informationLinkEN
                : informationLink
            )
          }
        >
		
          <img src={information} alt={"information-application"} width={35} />
          <p>{t("Πληροφορίες για το έργο")}</p>
        </div>
        {(iosAppLink || androidAppLink) && (
          <div className={classes.appsPlaceholder}>
            {iosAppLink && (
              <img
                src={apple}
                alt={"apple-application"}
                width={150}
                onClick={() => window.open(iosAppLink)}
              />
            )}
            {androidAppLink && (
              <img
                src={android}
                alt={"android-application"}
                width={150}
                onClick={() => window.open(androidAppLink)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeMessage;
