export const informationLink = "https://www.smartiscity.gr/dimos-kalamarias/";
export const informationLinkEN =
  "https://www.smartiscity.gr/en/dimos-kalamarias/";

export const diavLink = "https://skoufas.diavouleuseis.gr";

export const applicantsCitizen = [
  {
    name: "Σύνδεση",
    url: "https://platform.cityzenapp.gr/cityzen/dimosskoufa/login",
  },
  {
    name: "Εγγραφή",
    url: "https://platform.cityzenapp.gr/cityzen/dimosskoufa/register",
  },
  {
    name: "Πληροφορίες",
    url: "https://platform.cityzenapp.gr/cityzen/dimosskoufa/",
  },
];

export const androidAppLink = "https://play.google.com/store/apps/details?id=com.dotsoft.smartcity.kalamaria";
export const iosAppLink = "https://apps.apple.com/us/app/kalamaria-smart-city/id1636659266";

export const advertisements = [
  {
    title: "Σύστημα Αναφορών 4myCity",
    externalLink: "https://4mycity.kalamaria.gr/",
    img: "https://kalamaria.gr/wp-content/uploads/2016/04/mycity.png",
  },
  {
    title: "Εθελοντισμός στην Καλαμαριά",
    externalLink: "https://act4kalamaria.gr/",
    img: "https://kalamaria.smartiscity.gr/boximg/volunteers.png",
  },
  {
    title: "Green Tree",
    externalLink: "https://app.greentree.gr/el/map/4",
    img: "https://kalamaria.smartiscity.gr/boximg/GREENTREE.png",
  },
  {
    title: "Γεωπύλη",
    externalLink: "https://kalamaria.maps.arcgis.com/home/index.html",
    img: "https://kalamaria.smartiscity.gr/boximg/geopili.png",
  },
  {
    title: "Φόρμα Εγγραφής Επιχειρήσεων",
    externalLink:
      "https://kalamaria.terranet.gr/index.php?option=com_contact&view=contact&id=1&Itemid=142&lang=el",
    img: "https://kalamaria.smartiscity.gr/boximg/zo.png",
  }
];

export const welcomeMessageContent = [
  "Ο Δήμος Καλαμαριάς σας καλωσορίζει στην Πλατφόρμα Εξυπνης Πόλης.",
  "Ενημερωθείτε για τις συνθήκες που επικρατούν στην πόλη μας.",
];

export const baseUrl = "https://kalamaria.smartiscity.gr";

export const allTypesUrl = baseUrl + "/api/api.php?func=sensortypes";
export const allSensorsUrl = baseUrl + "/api/api.php?func=allsensors";

export const UvUrl = baseUrl + "/api/api.php?func=indexes";
export const enviURL = baseUrl + "/api/api.php?func=envi";
export const meshURL = baseUrl + "/api/api.php?func=mesh";
export const meteoURL = baseUrl + "/api/api.php?func=meteo";
export const poiURL = baseUrl + "/api/api.php?func=pois";
export const fleetoUrl = baseUrl + "/api/api.php?func=fleeto";
export const binsUrl = baseUrl + "/api/api.php?func=bins";
export const hydroUrl = baseUrl + "/api/api.php?func=hydro";
export const weatherUrl = baseUrl + "/api/api.php?func=forecast";
export const wifiUrl = baseUrl + "/api/api.php?func=hotspot";
export const parkingUrl = baseUrl + "/api/api.php?func=parkingAll";
export const allBinsCharts = baseUrl + "/api/api.php?func=allbinstats";
export const specificBinChart = baseUrl + "/api/api.php?func=binstats";
export const chartsUrls = baseUrl + "/api/api.php?func=stats";

export const gallerySmall = baseUrl + "/gallery/vars/Small/";
export const galleryBig = baseUrl + "/gallery/vars/Big/";

export const cityCenter = {
  lat: 40.576479,
  lng: 22.9377036,
};

// settings
export const showEnvironmentAddress = true;
